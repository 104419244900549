
import { Vue, Options } from 'vue-class-component';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import { OrderConfigurationType } from '@/models';
import { BaseButton, BaseSelect, BaseTextarea, BaseSelectCheckbox } from '@/lib/components';
import { MeniconOrderConfigurationTypesService, MeniconOrganisationService } from '@/services/api';
import { useProgressStore } from '@/stores/progress.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    organisationId: {
      type: String,
      required: true
    }
  },
  components: {
    BaseTextarea,
    BaseButton,
    BaseSelect,
    BaseSelectCheckbox
  }
})
export default class OrganisationSettingsPage extends Vue {
  organisationId!: string;
  progressStore = useProgressStore();
  notificationStore = useNotificationStore();
  loading = false;
  orderConfigurationTypeIds = [];

  orderConfigurationParameters = '';

  orderConfigurationTypes: Array<OrderConfigurationType> = [];
  errors = {};

  request: CancelTokenSource | null = null;

  meniconOrganisationService = new MeniconOrganisationService(this.organisationId);

  meniconOrderConfigurationTypesService = new MeniconOrderConfigurationTypesService();

  created() {
    this.progressStore.startProgress();
    this.fetchData();
  }

  unmounted() {
    this.progressStore.removeProgress();
    if (this.request) {
      this.request.cancel();
    }
  }

  get orderConfigurationTypeOptions() {
    return this.orderConfigurationTypes.map((orderLocationType) => ({
      value: orderLocationType.value,
      label: this.$t(`platform.org-unit.order-processing.types.${orderLocationType.value}`)
    }));
  }

  async fetchData() {
    this.loading = true;
    this.request = axios.CancelToken.source();
    const [orderConfigurationTypes, orderConfiguration] = await Promise.allSettled([
      this.fetchOrderConfigurationTypes(this.request.token),
      this.fetchOrderConfiguration(this.request.token)
    ]);

    // Set available types
    if (orderConfigurationTypes.status === 'fulfilled') {
      this.orderConfigurationTypes = orderConfigurationTypes.value;
    } else if (!axios.isCancel(orderConfigurationTypes.reason)) {
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.fetch-data')
      });
    }

    // Set values of existing configuration if available
    if (orderConfiguration.status === 'fulfilled') {
      this.orderConfigurationTypeIds = orderConfiguration.value.types;
      this.orderConfigurationParameters = orderConfiguration.value.parameters;
    } else {
      // TODO: Possibly show error notification if neither the OU or any of it's parents have a configuration set
      // this.$store.dispatch(ADD_NOTIFICATION, {
      //   title: `No Order Processing Configuration set`
      // });
    }

    orderConfigurationTypes && orderConfiguration
      ? this.progressStore.finishProgress()
      : this.progressStore.setError();
    this.loading = false;
  }

  async fetchOrderConfigurationTypes(cancelToken: CancelToken) {
    return (
      await this.meniconOrderConfigurationTypesService.index({
        cancelToken
      })
    ).data;
  }

  async fetchOrderConfiguration(cancelToken: CancelToken) {
    return (
      await this.meniconOrganisationService.getOrderConfiguration({
        cancelToken
      })
    ).data;
  }

  async save() {
    try {
      await this.meniconOrganisationService.updateOrCreateOrderConfiguration({
        types: this.orderConfigurationTypeIds,
        parameters: this.orderConfigurationParameters,
        active: true
      });

      this.errors = {};

      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.org-unit.order-processing.update-success')
      });
    } catch (error) {
      console.warn('Error updating Order Configuration', error);
      this.errors = error.response.data.errors;
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.org-unit.order-processing.update-error')
      });
    }
  }

  goBack() {
    this.$router.back();
  }
}
