import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "p-10 bg-white border rounded-lg" }
const _hoisted_3 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_4 = { class: "mt-10" }
const _hoisted_5 = { class: "mt-4" }
const _hoisted_6 = { class: "mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSelectCheckbox = _resolveComponent("BaseSelectCheckbox")!
  const _component_BaseTextarea = _resolveComponent("BaseTextarea")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('platform.org-unit.order-processing.title')), 1),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_BaseSelectCheckbox, {
              modelValue: _ctx.orderConfigurationTypeIds,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.orderConfigurationTypeIds) = $event)),
              "data-dusk": "org-order-config-types",
              options: _ctx.orderConfigurationTypeOptions,
              "is-optional": "",
              placeholder: _ctx.$t('platform.org-unit.order-processing.select-type'),
              label: _ctx.$t('platform.org-unit.order-processing.select-type'),
              error: _ctx.errors.types ? _ctx.errors.types[0] : null
            }, null, 8, ["modelValue", "options", "placeholder", "label", "error"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_BaseTextarea, {
              modelValue: _ctx.orderConfigurationParameters,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.orderConfigurationParameters) = $event)),
              label: _ctx.$t('platform.org-unit.order-processing.config'),
              class: "text-base font-regular",
              placeholder: _ctx.$t('platform.org-unit.order-processing.add-config'),
              instructions: _ctx.$t('platform.org-unit.order-processing.select-type-instructions'),
              error: _ctx.errors.parameters ? _ctx.errors.parameters[0] : null
            }, null, 8, ["modelValue", "label", "placeholder", "instructions", "error"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_BaseButton, {
              size: "large",
              "data-dusk": "org-settings-save-button",
              onClick: _ctx.save
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('platform.common.save-changes')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_BaseButton, {
              size: "large",
              color: "ghost",
              class: "ml-4",
              onClick: _ctx.goBack
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}